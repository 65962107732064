// * Init
$error-color: #ff4a4a;
$warning-color: #ffd227;
$info-color: #4ac6ff;
$success-color: #40d565;

$font-title: "Rethink Sans", sans-serif;
$font-subtext: "Open Sans", sans-serif;
$font-text: "Open Sans", sans-serif;

:root {
  --error-color: #{$error-color};
  --warning-color: #{$warning-color};
  --info-color: #{$info-color};
  --success-color: #{$success-color};

  // * for RGBA
  --rgb-error-color: 255, 74, 74;
  --rgb-warning-color: 255, 210, 39;
  --rgb-info-color: 74, 198, 255;
  --rgb-success-color: 64, 213, 101;

  // * Font
  --font-title: #{$font-title};
  --font-subtext: #{$font-subtext};
  --font-text: #{$font-text};
}

// * ICONS CAP
@import url("../assets/fonts/font-awesome/all.css");
@import url("../assets/fonts/linearicon/linearicon.css");
@import url("../assets/fonts/themify/themify.css");

@import url("./bootstrap-grid.css");

// * Fonts
@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@200..700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Anton&family=Cairo:wght@200..1000&display=swap");

html {
  height: 100%;
}
body {
  margin: 0;
  height: 100%;
  font-family: var(--font-text);
}

.font__title {
  font-family: var(--font-title);
}
.font__subtext {
  font-family: var(--font-subtext);
}
.font__text {
  font-family: var(--font-text);
}

/**
** GENERAL
**/

@import "ngx-toastr/toastr";

[tooltip]:not([tooltip-desc="false"]) {
  position: relative;
  &::before {
    content: attr(tooltip);
    position: absolute;
    top: calc(5px + 100%);
    left: 50%;
    transform: translateX(-50%);
    width: auto;
    max-width: 110%;
    text-align: center;
  }
}

mat-paginator {
  --mat-paginator-container-text-size: 14px;
}
.mat-table,
.mat-menu-item {
  font-family: var(--font-subtext);
}
app-cap-datatable {
  mat-paginator {
    .mat-paginator-container {
      justify-content: space-between;
    }
  }
}
.pagination_table-out {
  width: auto;
  display: flex;
  flex-flow: wrap;
  gap: 0.5rem;
  list-style: none;
  .paginate_button {
    position: relative;
    display: block;
    .page-link {
      background: #f7f5f5;
      color: #818181;
      width: auto;
      min-width: 40px;
      height: 40px;
      line-height: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 10px;
      &.active {
        background: var(--primary-color);
        color: #fff;
      }
      &:hover:not(.active):not(:disabled):not(.disabled) {
        background: var(--primary-color);
        color: #fff;
        opacity: 0.9;
      }
      &.disabled,
      &:disabled {
        background-color: #f7f5f5;
        color: #c9c9c9;
        pointer-events: none;
      }
    }
  }
}
.input__color {
  padding: 0;
  -webkit-appearance: none;
  appearance: none;
  border: 0;
  height: 25px;
  width: auto;
  min-width: 45px;
  cursor: pointer;
  &::-webkit-color-swatch-wrapper {
    padding: 0;
  }
  &::-webkit-color-swatch {
    border: none;
    border-radius: 4px;
  }
}

.btn__back {
  display: block;
  --opacity: 1;
  --tw-bg-opacity: 1;
  --background: #000;
}

.loading_wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999999999999999;
  .backdrop_loading {
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 1;
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .loading__content {
    display: block;
    width: auto;
    z-index: 2;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    padding: 1.5rem 2rem;
    border-radius: 15px;
    text-align: center;
    p {
      display: block;
      margin: 0;
      text-align: center;
      width: 100%;
      font-weight: 500;
      font-size: 16px;
    }
  }
}

// Loading Default
.loads-ellipsis {
  margin: 0 auto;
  display: block;
  position: relative;
  width: 80px;
  height: 80px;
  div {
    position: absolute;
    top: 33px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: var(--primary-color);
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
    &:nth-child(1) {
      left: 8px;
      animation: lds-ellipsis1 0.6s infinite;
    }
    &:nth-child(2) {
      left: 8px;
      animation: lds-ellipsis2 0.6s infinite;
    }
    &:nth-child(3) {
      left: 32px;
      animation: lds-ellipsis2 0.6s infinite;
    }
    &:nth-child(4) {
      left: 56px;
      animation: lds-ellipsis3 0.6s infinite;
    }
  }
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}

// Loading 2
.loadingio-spinner-dual-ball {
  width: 100px;
  height: 100px;
  display: inline-block;
  overflow: hidden;
  background: transparent;
}
.ldio-dualball div {
  position: absolute;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  top: 30px;
  left: 10px;
}
.ldio-dualball div:nth-child(1) {
  background: var(--primary-color);
  animation: ldio-dualball 1s linear infinite;
  animation-delay: -0.5s;
}
.ldio-dualball div:nth-child(2) {
  background: var(--second-color);
  animation: ldio-dualball 1s linear infinite;
  animation-delay: 0s;
}
.ldio-dualball div:nth-child(3) {
  background: var(--primary-color);
  animation: ldio-dualball-o 1s linear infinite;
  animation-delay: -0.5s;
}
.ldio-dualball {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0;
}
.ldio-dualball div {
  box-sizing: content-box;
}
@keyframes ldio-dualball-o {
  0% {
    opacity: 1;
    transform: translate(0 0);
  }
  49.99% {
    opacity: 1;
    transform: translate(40px, 0);
  }
  50% {
    opacity: 0;
    transform: translate(40px, 0);
  }
  100% {
    opacity: 0;
    transform: translate(0, 0);
  }
}
@keyframes ldio-dualball {
  0% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(40px, 0);
  }
  100% {
    transform: translate(0, 0);
  }
}

// Loading 3
.loading-roller {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
  div {
    animation: loading_roaller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    transform-origin: 40px 40px;
    &:after {
      content: " ";
      display: block;
      position: absolute;
      width: 7px;
      height: 7px;
      border-radius: 50%;
      background: var(--third-color);
      margin: -4px 0 0 -4px;
    }
    &:nth-child(1) {
      animation-delay: -0.036s;
      &:after {
        top: 63px;
        left: 63px;
      }
    }
    &:nth-child(2) {
      animation-delay: -0.072s;
      &:after {
        top: 68px;
        left: 56px;
      }
    }
    &:nth-child(3) {
      animation-delay: -0.108s;
      &:after {
        top: 71px;
        left: 48px;
      }
    }
    &:nth-child(4) {
      animation-delay: -0.144s;
      &:after {
        top: 72px;
        left: 40px;
      }
    }
    &:nth-child(5) {
      animation-delay: -0.18s;
      &:after {
        top: 71px;
        left: 32px;
      }
    }
    &:nth-child(6) {
      animation-delay: -0.216s;
      &:after {
        top: 68px;
        left: 24px;
      }
    }
    &:nth-child(7) {
      animation-delay: -0.252s;
      &:after {
        top: 63px;
        left: 17px;
      }
    }
    &:nth-child(8) {
      animation-delay: -0.288s;
      &:after {
        top: 56px;
        left: 12px;
      }
    }
  }
}
@keyframes loading_roaller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.swal2-popup.swal2-modal.alert_table-swal {
  width: auto;
  max-width: 100%;
  .ctt_table {
    table {
      font-size: 1.075rem !important;
      vertical-align: middle !important;
      margin-bottom: 1rem;
      color: #181c32;
      border-color: #eff2f5;
      caption-side: bottom;
      border-collapse: collapse;
      thead,
      tbody,
      tfoot,
      tr,
      td,
      th {
        border-color: inherit;
        border-style: solid;
        border-width: 0;
      }
      thead {
        vertical-align: bottom;
        color: #b5b5c3 !important;
        font-weight: 600 !important;
        font-size: 0.95rem !important;
        text-transform: uppercase !important;
        tr {
          border-bottom-width: 1px;
          border-bottom-style: dashed;
          border-bottom-color: #eff2f5;
        }
      }
      td,
      th {
        padding-top: 1.25rem;
        padding-bottom: 1.25rem;
        padding: 0.75rem 0.75rem;
        &:first-child {
          min-width: 55px;
        }
        &:not(:first-child) {
          min-width: 100px;
        }
        &:nth-child(3) {
          min-width: 150px;
        }
        &:nth-child(7) {
          min-width: 200px;
        }
      }
      tr {
        &.danger {
          background-color: #fee2e2;
        }
        &.success {
          background-color: #dcfce7;
        }
      }
      tr:last-child,
      th:last-child,
      td:last-child {
        padding-right: 0;
      }
      tr:first-child,
      th:first-child,
      td:first-child {
        padding-left: 0;
      }
      .table.table-row-dashed tr {
        border-bottom-width: 1px;
        border-bottom-style: dashed;
        border-bottom-color: #eff2f5;
      }
      tfoot tr:last-child,
      tbody tr:last-child {
        border-bottom: 0 !important;
      }
    }
  }
  .two_line-hidden {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

input:focus-visible {
  outline: none !important;
}

.data_loader {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  .btn__roller {
    display: inline-block;
    margin-left: 0;
    margin-right: 0;
    width: 25px;
    position: relative;
    --color-spin: var(--primary-color);
    --color2-spin: var(--second-color);
    --size-spin: 3px;
    &::before {
      content: "";
      display: block;
      padding-bottom: 100%;
    }
  }
}
.content__loader_min {
  width: auto;
  display: block;
  max-width: 100%;
  text-align: center;
  padding: 1rem;
}
.loader__spin {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  animation: rotate 1s linear infinite;
  &::before {
    content: "";
    box-sizing: border-box;
    position: absolute;
    inset: 0px;
    border-radius: 50%;
    border: var(--size-spin, 5px) solid var(--color-spin, #fff);
    animation: prixClipFix 2s linear infinite;
  }
}
@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes prixClipFix {
  0% {
    clip-path: polygon(50% 50%, 0 0, 0 0, 0 0, 0 0, 0 0);
  }
  25% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 0, 100% 0, 100% 0);
  }
  50% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 100% 100%, 100% 100%);
  }
  75% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 100%);
  }
  100% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 0);
  }
}

.loader__spin2 {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  animation: rotate2 1s linear infinite;
  &::before,
  &::after {
    content: "";
    box-sizing: border-box;
    position: absolute;
    inset: 0px;
    border-radius: 50%;
    border: var(--size-spin, 5px) solid var(--color-spin, #fff);
    animation: prixClipFix2 2s linear infinite;
  }
  &::after {
    transform: rotate3d(90, 90, 0, 180deg);
    border-color: var(--color2-spin, #000);
  }
}

@keyframes rotate2 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes prixClipFix2 {
  0% {
    clip-path: polygon(50% 50%, 0 0, 0 0, 0 0, 0 0, 0 0);
  }
  50% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 0, 100% 0, 100% 0);
  }
  75%,
  100% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 100% 100%, 100% 100%);
  }
}

html {
  &.cdk-global-scrollblock {
    &:has(.isFullScreen) {
      overflow: hidden;
    }
  }
}

/* ** GENERAL - BODY ** */
body.webapp-site {
  position: relative;
  height: auto;
  margin: 0;
  min-height: 100%;
  @import "master/main";
  @import "master/responsive";
}
