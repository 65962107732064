// ** --------Responsive

// md tailwind
@media screen and (min-width: 768px) {
  swal2-popup.swal2-modal.alert_table-swal ctt_table table {
    width: 100%;
  }
  .card-general {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }
}

// sm Tailwind
@media screen and (min-width: 640px) {
  .content__product-view .content__product-detail {
    padding: 1rem 2rem;
  }
}

@media screen and (min-width: 361px) and (max-width: 767px) {
}

@media screen and (min-width: 1024px) {
  // Tailwind lg
  &.hide__menu {
    .hide__menu-desktop {
      grid-template-columns: minmax(0, 0) minmax(0, 1fr);
    }
  }
}

@media screen and (max-width: 1279px) {
  .content__shop .content__shop-events .shop__events-item {
    &.type__match {
      .shop__events-item-match .shop__events-item-vs h3 {
        font-size: 55px;
      }
      .shop__events-item-desc p {
        font-size: 22px;
      }
    }
    &.type__event {
      .shop__events-item-event-detail {
        h3 {
          font-size: 55px;
        }
        h5 {
          font-size: 35px;
        }
        p {
          font-size: 22px;
        }
      }
    }
  }
  .content__product-view .content__product-img {
    width: 400px;
  }

  .content__trivia
    .content__trivia-group-process
    .content__trivia-group-process-answers {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

@media screen and (max-width: 991px) {
  .content__shop .content__shop-events .shop__events-item {
    &.type__match {
      .shop__events-item-match .shop__events-item-vs h3 {
        width: 100%;
        small {
          display: block;
          width: 100%;
        }
      }
      .shop__events-item-desc
        .buy__tickets.cy__btn.mdc-button
        .mdc-button__label {
        font-size: 1rem;
      }
    }
    .shop__events-item-event-detail-btns {
      .buy__tickets.cy__btn.mdc-button .mdc-button__label {
        font-size: 1rem;
      }
    }
    &.type__event-img {
      .shop__events-item-event-detail-btns {
        right: 0;
        left: 0;
        margin: auto;
        justify-content: center;
      }
    }
  }
  .content__match-event {
    h5 {
      font-size: 15px;
    }
    .match__tournament {
      img {
        width: 25px;
        height: 25px;
      }
    }
    .match__vs-img {
      .match__team-a,
      .match__team-b {
        width: 50px;
        height: 50px;
      }
      .match__team-vs {
        width: 33px;
        height: 33px;
      }
    }
    .match__vs-detail {
      .match__vs-name h4 {
        font-size: 18px;
      }
    }
  }
  .content__product-view .content__product-img {
    width: 350px;
  }
  .content__service-discount .content__service-discount-info {
    .content__service-discount-detail,
    .content__service-discount-image {
      width: 100%;
      flex: none;
    }
    .content__service-discount-detail {
      padding-right: 1rem;
      padding-top: 4rem;
    }
    .content__service-discount-image {
      order: -1;
      padding-left: 1rem;
      padding-bottom: 4rem;
      .content__service-discount-discount {
        top: 100%;
        left: 50%;
        svg text {
          font-size: 12rem;
        }
      }
    }
  }

  .content__trivia {
    display: block;
    .content__trivia-group-init {
      margin: 0 auto 1.5rem;
    }
    .content__trivia-group-process {
      max-height: unset;
      .content__trivia-group-process-status {
        position: sticky;
        top: 93px;
        background: white;
        z-index: 1;
        &.type__footer {
          position: sticky;
          bottom: 0;
          background: white;
          padding: 0.6rem 1rem;
        }
      }
      .content__trivia-group-process-answers-scroll {
        overflow: unset;
        max-height: unset;
      }
      .content__trivia-group-process-answers {
        grid-template-columns: repeat(3, minmax(0, 1fr));
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .content__shop .content__shop-events .shop__events-item {
    &.type__match {
      .shop__events-item-match {
        display: flex;
        gap: 1rem;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        .shop__events-item-vs h3 {
          font-size: 45px;
        }
        .team-a,
        .team-b {
          .team__img-extra {
            width: 80%;
          }
        }
      }
    }
    &.type__event {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      .shop__events-item-event-detail {
        h3 {
          font-size: 45px;
        }
        h5 {
          font-size: 30px;
        }
      }
    }
  }
  .content__match-event {
    grid-template-columns: minmax(0, 1fr);
    .match__vs-detail {
      text-align: center;
    }
    .match__tournament {
      img {
        width: 30px;
        height: 30px;
      }
    }
    .match__vs-img {
      .match__team-a,
      .match__team-b {
        width: 80px;
        height: 80px;
      }
      .match__team-vs {
        width: 50px;
        height: 50px;
      }
    }
  }
  .img__bg-nf {
    background-image: url("/assets/img/figure-resp.svg");
  }
  .title__page {
    font-size: 2.25rem;
  }
  .content__product-view .content__product-img {
    width: 100%;
  }
  .content__payment-card .delete__payment-card {
    opacity: 1;
    visibility: visible;
    pointer-events: auto;
  }
  .content__benefits .content__benefits-details .content__benefits-data {
    order: -1;
    width: 100%;
    margin-bottom: 1rem;
    text-align: center;
  }
  .content__benefits-categories {
    .content__benefits-categories-steps-header {
      overflow: hidden;
      .content__benefits-categories-steps {
        margin: 0 -1rem;
        width: calc(100% + (1rem * 2));
        .content__benefits-categories-step {
          width: 100%;
          flex: 1;
        }
      }
    }
  }
  .content__btn-btn .content__btn-btn-tooltip {
    right: unset;
    left: 0;
    top: calc(100% + 10px);
    &::after {
      position: absolute;
      bottom: 100%;
      left: 50%;
      margin-left: -5px;
      border-width: 5px;
      top: unset;
      border-style: solid;
      border-color: transparent transparent #555 transparent;
    }
  }

  .content__trivia .content__trivia-group-process {
    .content__trivia-group-process-status {
      top: 80px;
    }
    .content__trivia-group-process-answers {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
  }
}

@media screen and (max-width: 639px) {
  .cdk-overlay-container .cdk-overlay-connected-position-bounding-box {
    background-color: rgba(0, 0, 0, 0.36);
    width: 100% !important;
    right: 0 !important;
    left: 0 !important;
    .cdk-overlay-pane {
      position: unset;
      margin: auto;
    }
  }
  .content__payment-card {
    aspect-ratio: 1/0.65;
    .first__card {
      .number__card {
        font-size: 17px;
      }
      .content__logo-type-card .logo__type-card {
        width: 70px;
        height: 35px;
      }
    }
    .detail__card {
      .name__card,
      .date__card {
        span {
          font-size: 10px;
        }
        p {
          font-size: 14px;
        }
      }
    }
    &.card__list,
    &.card__select-list:not(.selected__card) {
      .first__card {
        .number__card {
          width: calc(100% - 40px);
          font-size: 14px;
        }
        .content__logo-type-card .logo__type-card {
          width: 40px;
          height: 23px;
        }
      }
    }
  }
  .content__benefits-categories
    .content__benefits-categories-steps-header
    .content__benefits-categories-steps {
    --size-top-card: 4rem;
    .content__benefits-categories-step button {
      img {
        width: 80px;
      }
      span {
        margin: 0.5rem 0 0;
      }
    }
  }
}

@media screen and (max-width: 512px) {
  .content__shop .content__shop-events .shop__events-item {
    &.type__match {
      .shop__events-item-match {
        .shop__events-item-vs h3 {
          font-size: 30px;
        }
        .team-a,
        .team-b {
          width: 150px;
        }
      }
      .shop__events-item-desc p {
        font-size: 19px;
      }
    }
    &.type__event {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      .shop__events-item-event-detail {
        h3 {
          font-size: 30px;
        }
        h5 {
          font-size: 20px;
        }
        p {
          font-size: 18px;
        }
      }
    }
  }
  .content__info-renov {
    grid-template-columns: minmax(0, max-content) minmax(0, 1fr);
    .content__info-btn {
      margin-top: 1rem;
      text-align: center;
      grid-column: 1 / -1;
    }
  }
  .content__trivia {
    .content__trivia-group-init {
      .content__trivia-init h3 {
        font-size: 1.6rem;
      }
      .content__trivia-init-info.animate-back-in-up {
        margin-top: -1.5rem;
      }
    }
    .content__trivia-group-process {
      padding: 1.5rem;
      .content__trivia-group-process-answers {
        grid-template-columns: repeat(1, minmax(0, 1fr));
      }
    }
  }
}

@media screen and (min-width: 991px) {
  .container_cy.cb-auth {
    width: 915px;
  }
  mat-stepper {
    &.stepper_full-step {
      div.mat-horizontal-stepper-wrapper,
      div.mat-horizontal-content-container,
      div.mat-horizontal-stepper-content {
        height: 100%;
        overflow: hidden;
      }
      .mat-horizontal-content-container {
        width: 100%;
        position: relative;
        .mat-horizontal-stepper-content {
          width: 100%;
          position: absolute;
          top: 0;
          left: 0;
          visibility: hidden;
        }
      }
    }
  }
}

@media screen and (min-width: 1280px) {
  .ctt__checkout .ctt__checkout-divisor {
    grid-template-columns: minmax(0, 360px) minmax(0, 1fr);
  }
}

@media screen and (min-width: 1350px) {
  .container_cy.cb-auth {
    width: 1200px;
  }
}

@media screen and (min-width: 1536px) {
  .container_cy.cb-auth {
    width: 1440px;
  }
}
